<template>
  <div class="gameStat">
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <b-container fluid>
      <b-row>
        <b-col cols="8">
          <b-form-group label="Category">
            <b-form-radio-group
              v-model="selectedCategory"
              :options="categoryOptions"
              name="radio-inline1"
            ></b-form-radio-group>
            <span v-if="selectedCategory === 'm'">Start Time: {{startedAt}}</span>
          </b-form-group>
          <b-form-group label="Map">
            <b-form-radio-group
              v-model="selectedRadio"
              @change="getStaticsData"
              :options="options"
              name="radio-inline2"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group label="Telemetry">
            <b-form-radio-group
              v-model="selectedLive"
              :options="liveOptions"
              name="radio-inline3"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- <b-button v-b-modal.pwd-modal class="save-btn"> -->
            <b-button @click="getTableData" class="save-btn">
            <JsonCSV :data="downloadData" :name="downloadFileName">
              <b-icon
                class="mr-2"
                icon="cloud-download"
                aria-hidden="true"
              ></b-icon>
              Download
            </JsonCSV>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <!-- <label>{{statusText}}</label> -->
    <b-tabs v-model="tabIndex">
      <b-tab title="Team" active>
        <MyGrid ref="teamSeries"
          v-if="selectedCategory === 's'"
          :columns="teamStatColumns"
          :tableDatas="seriesTeamData"
          :isKillLog="false"
          
        />
        <MyGrid ref="teamTournament"
          v-if="selectedCategory === 't'"
          :columns="teamStatColumns"
          :tableDatas="tournamentTeamData"
          :isKillLog="false"
        />
        <MyGrid ref="teamMatch"
          v-if="selectedCategory === 'm'"
          :columns="teamStatColumns"
          :tableDatas="matchTeamData"
          :isKillLog="false"
        />
      </b-tab>
      <b-tab title="Player">
        <MyGrid ref="playerSeries"
          v-if="selectedCategory === 's'"
          :columns="playerStatColumns"
          :tableDatas="seriesPlayerData"
          :isKillLog="false"
        />
        <MyGrid ref="playerTournament"
          v-if="selectedCategory === 't'"
          :columns="playerStatColumns"
          :tableDatas="tournamentPlayerData"
          :isKillLog="false"
        />
        <MyGrid ref="playerMatch"
          v-if="selectedCategory === 'm'"
          :columns="playerStatColumns"
          :tableDatas="matchPlayerData"
          :isKillLog="false"
        />
      </b-tab>
      <b-tab title="KillLog" v-if="selectedCategory === 'm'">
        <MyGrid ref="killLog"
          :columns="killLogColumns"
          :tableDatas="killLogData2"
          :isKillLog="true"
        />
      </b-tab>
      <b-tab title="RACE24" v-if="selectedSeriesType">
        <MyGrid ref="race24"
          :columns="race24Columns"
          :tableDatas="race24TeamData"
          :isKillLog="false"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Constant from '@/constant'

import JsonCSV from 'vue-json-csv'
import MyGrid from '@/components/customTable'
// import MyGrid2 from '@/components/customTable2'
import 'vue-nav-tabs/themes/vue-tabs.css'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  // props: ['tournamentid', 'selectedSeries'],
  components: {
    MyGrid,
    // MyGrid2,
    JsonCSV,
    Loading
  },
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', true)
    this.$store.commit('refreshTeamRoster', false)
    this.getStaticsData()
  },
  data () {
    return {
      // Name Kills Assists Headshot Ratio(%)
      // Time Survived (API, secs) Time Survived(Realtime, secs)
      // Revives Total Damage Knocks Knocked Painkillers Grenade Drinks Bandages Killed By
      // teamStatColumns: ['teamName', 'teamKillSum', 'teamDamageDealt', 'teamAssist'],
      tabIndex: 0,
      // statusText: '',
      // teamStatColumns: Constant.TEAM_STAT_COLUMNS,
      teamStatColumns: [],
      // playerStatColumns: Constant.PLAYER_STAT_COLUMNS,
      playerStatColumns: [],
      killLogColumns: Constant.KILLLOG_COLUMNS,
      // killLogColumns: [],
      seriesTeamData: [],
      seriesPlayerData: [],
      tournamentTeamData: [],
      tournamentPlayerData: [],
      matchTeamData: [],
      matchPlayerData: [],
      killLogData2: [],
      race24TeamData: [],
      race24Columns: [],
      options: [
        { text: 'Total', value: 'total' },
        { text: 'Erangel', value: 'erangel' },
        { text: 'Miramar', value: 'miramar' },
        { text: 'Taego', value: 'taego' },
        { text: 'Sanhok', value: 'sanhok' },
        { text: 'Vikendi', value: 'vikendi' },
        { text: 'Deston', value: 'deston' },
        { text: 'Rondo', value: 'rondo' }
      ],
      categoryOptions: [
        { text: 'Series', value: 's' },
        { text: 'Tournament', value: 't' },
        { text: 'Match', value: 'm' }
      ],
      liveOptions: [
        { text: 'Simple', value: true },
        { text: 'Normal', value: false }
      ],
      selectedRadio: 'total',
      selectedCategory: 't',
      selectedLive: true,
      waitSeriesTeam: false,
      waitSeriesPlayer: false,
      waitTournamentTeam: false,
      waitTournamentPlayer: false,
      waitMatchesTeam: false,
      waitMatchesPlayer: false,
      downloadFileName: '',
      downloadData: [],
      startedAt: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.userName
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    series () {
      return this.$store.state.series
    },
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    selectedMatch () {
      return this.$store.state.selectedMatch
    },
    selectedSeriesType () {
      return this.$store.state.selectedSeriesType === 'race'
    },
    isLoading () {
      return (
        this.waitSeriesTeam ||
        this.waitSeriesPlayer ||
        this.waitTournamentTeam ||
        this.waitTournamentPlayer ||
        this.waitMatchesTeam ||
        this.waitMatchesPlayer
      )
    }
  },
  watch: {
    selectedSeries () {
      this.getStaticsData()
    },
    selectedTournamentId () {
      this.getStaticsData()
    },
    selectedMatch () {
      this.getStaticsData()
    },
    selectedCategory () {
      this.getStaticsData()
    },
    selectedLive (newVal) {
      console.log('selectedLive', newVal)
      this.getStaticsData()
    },
    // selectedRadio () {
    //   console.log('selected radio change')
    //   this.getStaticsData()
    // },
    tabIndex () {
      // 탭인덱스 체인지가 2번됨. 킬로그 탭이나 race24탭이 추가되면서 인덱스가 변경됨
      this.getStaticsData()
    },
    isLoading (newVal) {
      if (newVal === false) {
        this.setDownloadData()
      }
    }
  },
  methods: {
    getTableData(){
      
      console.log()

      this.downloadData.splice(0)

      const category = this.selectedCategory
      const tabIdx = this.tabIndex
      if (category === 's') {
        if (tabIdx === 0) {
          this.downloadFileName = `series-team-${this.selectedRadio}-${this.selectedSeries}.csv`
          this.downloadData.push(...this.$refs.teamSeries.sortedItems)
        } else if (tabIdx === 1) {
          this.downloadFileName = `series-player-${this.selectedRadio}-${this.selectedSeries}.csv`
          this.downloadData.push(...this.$refs.playerSeries.sortedItems)
        } else if (tabIdx === 2) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.$refs.race24.sortedItems)
        }
      } else if (category === 't') {
        if (tabIdx === 0) {
          this.downloadFileName = `tournament-team-${this.selectedRadio}-${this.selectedTournamentId}.csv`
          this.downloadData.push(...this.$refs.teamTournament.sortedItems)
        } else if (tabIdx === 1) {
          this.downloadFileName = `tournament-player-${this.selectedRadio}-${this.selectedTournamentId}.csv`
          this.downloadData.push(...this.$refs.playerTournament.sortedItems)
        } else if (tabIdx === 2) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.$refs.race24.sortedItems)
        }
      } else if (category === 'm') {
        if (tabIdx === 0) {
          this.downloadFileName = `match-team-${this.selectedMatch}.csv`
          this.downloadData.push(...this.$refs.teamMatch.sortedItems)
        } else if (tabIdx === 1) {
          this.downloadFileName = `match-player-${this.selectedMatch}.csv`
          this.downloadData.push(...this.$refs.playerMatch.sortedItems)
        } else if (tabIdx === 2) {
          this.downloadFileName = `match-killLog-${this.selectedMatch}.csv`
          this.downloadData.push(...this.$refs.killLog.sortedItems)
        } else if (tabIdx === 3) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.$refs.race24.sortedItems)
        }
      }
      console.log(this.downloadData)

    },
    setDownloadData () {
      this.downloadData.splice(0)

      const category = this.selectedCategory
      const tabIdx = this.tabIndex
      if (category === 's') {
        if (tabIdx === 0) {
          this.downloadFileName = `series-team-${this.selectedRadio}-${this.selectedSeries}.csv`
          this.downloadData.push(...this.seriesTeamData)
        } else if (tabIdx === 1) {
          this.downloadFileName = `series-player-${this.selectedRadio}-${this.selectedSeries}.csv`
          this.downloadData.push(...this.seriesPlayerData)
        } else if (tabIdx === 2) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.race24TeamData)
        }
      } else if (category === 't') {
        if (tabIdx === 0) {
          this.downloadFileName = `tournament-team-${this.selectedRadio}-${this.selectedTournamentId}.csv`
          this.downloadData.push(...this.tournamentTeamData)
        } else if (tabIdx === 1) {
          this.downloadFileName = `tournament-player-${this.selectedRadio}-${this.selectedTournamentId}.csv`
          this.downloadData.push(...this.tournamentPlayerData)
        } else if (tabIdx === 2) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.race24TeamData)
        }
      } else if (category === 'm') {
        if (tabIdx === 0) {
          this.downloadFileName = `match-team-${this.selectedMatch}.csv`
          this.downloadData.push(...this.matchTeamData)
        } else if (tabIdx === 1) {
          this.downloadFileName = `match-player-${this.selectedMatch}.csv`
          this.downloadData.push(...this.matchPlayerData)
        } else if (tabIdx === 2) {
          this.downloadFileName = `match-killLog-${this.selectedMatch}.csv`
          this.downloadData.push(...this.killLogData2)
        } else if (tabIdx === 3) {
          this.downloadFileName = `race24-${this.selectedSeries}.csv`
          this.downloadData.push(...this.race24TeamData)
        }
      }
    },
    getRace24TeamStats () {
      this.waitTournamentTeam = true
      // const api = `api/pubg/liveleaderboard_race?account_id=${this.user}&series=${this.selectedSeries}&tournament_id=${this.selectedTournamentId}&mode=post`
      const api = `api/pubg/liveleaderboard_race?account_id=${this.user}&series=${this.selectedSeries}&format=race`
      const timeout = 5000
      axios
        .get(api, {timeout})
        .then(response => {
          this.parseRace24Statistics(response.data.data, this.race24TeamData)
        })
        .catch(ex => {
          console.warn(ex + ' getRace24TeamStats error')
        })
        .finally(() => {
          this.waitTournamentTeam = false
        })
    },
    getMatchTeamStats () {
      this.waitMatchesTeam = true
      this.startedAt = ''
      const api = `api/pubg/leaderboard2/${this.user}/${this.selectedSeries}?tournament_id=${this.selectedTournamentId}&match_id=${this.selectedMatch}&live=${this.selectedLive}&format=web`

      axios
        .get(api)
        .then(response => {
          console.log('get match teamStat')
          this.parseStatistics(response.data.data, this.matchTeamData)
          if (response.data.data.match_ids && response.data.data.match_ids.length > 0) {
            this.startedAt = response.data.data.match_ids[0].startedAt
          }
        })
        .catch(ex => {
          console.warn('getMatchTeamStats' + ex)
        })
        .finally(() => {
          this.waitMatchesTeam = false
        })
    },
    getMatchPlayerStats () {
      this.waitMatchesPlayer = true

      const api = `api/pubg/playerStats4/${this.user}/${this.selectedSeries}?tournament_id=${this.selectedTournamentId}&match_id=${this.selectedMatch}&live=${this.selectedLive}&format=web`

      axios
        .get(api)
        .then(response => {
          this.parseStatisticsPlayer(response.data.data, this.matchPlayerData)
        })
        .catch(ex => {
          console.warn('getMatchTeamStats' + ex)
        })
        .finally( () => {
          this.waitMatchesPlayer = false
        })
    },
    getSeriesTeamStat () {
      this.waitSeriesTeam = true
      const api = `api/pubg/leaderboard2/${this.user}/${this.selectedSeries}?live=${this.selectedLive}&map=${this.selectedRadio}&format=web`
      axios
        .get(api)
        .then(response => {
          console.log('get series teamStat')
          this.parseStatistics(response.data.data, this.seriesTeamData)
        })
        .catch(ex => {
          console.warn(ex + 'error')
        })
        .finally(() => {
          this.waitSeriesTeam = false
        })
    },
    getSeriesPlayerStat () {
      this.waitSeriesPlayer = true
      const api = `api/pubg/playerStats4/${this.user}/${this.selectedSeries}?live=${this.selectedLive}&map=${this.selectedRadio}&format=web`
      axios
        .get(api)
        .then(response => {
          this.parseStatisticsPlayer(response.data.data, this.seriesPlayerData)
        })
        .catch(ex => {
          console.warn(ex + 'error')
        })
        .finally(() => {
          this.waitSeriesPlayer = false
        })
    },
    getTournamentTeamStat () {
      this.waitTournamentTeam = true
      const api = `api/pubg/leaderboard2/${this.user}/${this.selectedSeries}?tournament_id=${this.selectedTournamentId}&live=${this.selectedLive}&map=${this.selectedRadio}&format=web`
      axios
        .get(api)
        .then(response => {
          this.parseStatistics(response.data.data, this.tournamentTeamData)
        })
        .catch(ex => {
          console.warn(ex + 'error')
        })
        .finally( () => {
          this.waitTournamentTeam = false
        })
    },
    getTournamentPlayerStat () {
      this.waitTournamentPlayer = true

      const api = `api/pubg/playerStats4/${this.user}/${this.selectedSeries}?tournament_id=${this.selectedTournamentId}&live=${this.selectedLive}&map=${this.selectedRadio}&format=web`
      axios
        .get(api)
        .then(response => {
          this.parseStatisticsPlayer(
            response.data.data,
            this.tournamentPlayerData
          )
        })
        .catch(ex => {
          console.warn(ex + 'error')
        })
        .finally(() => {
          this.waitTournamentPlayer = false
        })
    },
    getStaticsData () {
      const category = this.selectedCategory
      const tabIdx = this.tabIndex
      if (category === 's') {
        // console.log('sss tabIdx===' + tabIdx)
        if (tabIdx === 0) {
          this.getSeriesTeamStat()
        } else if (tabIdx === 1) {
          this.getSeriesPlayerStat()
        } else if (tabIdx === 2) {

          this.getRace24TeamStats()
        }
      } else if (category === 't') {
        if (tabIdx === 0) {
          this.getTournamentTeamStat()
        } else if (tabIdx === 1) {
          this.getTournamentPlayerStat()
        } else if (tabIdx === 2) {
          this.getRace24TeamStats()
        }
      } else if (category === 'm') {
        // console.log('mmm tabIdx===' + tabIdx)
        if (tabIdx === 0) {
          this.getMatchTeamStats()
        } else if (tabIdx === 1) {
          this.getMatchPlayerStats()
        } else if (tabIdx === 2) {
          this.getKillLogData()
        } else if (tabIdx === 3) {
          this.getRace24TeamStats()
        }
      }
    },
    getKillLogData () {
      this.waitMatchesPlayer = true
      const api = `api/pubg/matchresult/${this.selectedMatch}`
      axios
        .get(api)
        .then(response => {
          this.parseKillLog(response.data.data, this.killLogData2)
        })
        .catch(ex => {
          console.error(ex + 'error : getKilLogData')
        })
        .finally( () => {
          this.waitMatchesPlayer = false
        })
    },
    parseKillLog (data, viewData) {
      viewData.splice(0)
      // this.killLogColumns = this.getDataColumns(data.players)
      for (const key in data.players) {
        var aPlayer = data.players[key]
        if (aPlayer.isAlive === false) {
          var killLog = {
            // time: aPlayer.eliminatedAt,
            time: aPlayer.deathAt,
            time_str: aPlayer.deathAt,
            // time_srt: moment.unix(aPlayer.deathAt).format('HH:mm:ss'),
            Killer: aPlayer.killedBy,
            Victim: aPlayer.name
          }
          viewData.push(killLog)
        }
      }
      viewData.sort(function (a, b) {
        return b.time - a.time
      })
    },
    getDataColumns(data){
      const keys = Object.keys(data[0])
      let cols = []
      for (const key of keys) {
        if (key.startsWith('_')) continue
        let col = {
          //field: key,
          key: key,
          label: key,
          type: typeof data[0][key],
          customSort: true,
          thClass: 'text-left align-top'
        }
        if (key === 'Team Color') {
          col = {
            field: key,
            label: key,
            thClass: 'text-left align-top'
          }
        }
        cols.push(col)
      }
      return cols
    },
    parseRace24Statistics (data, viewData) {
      viewData.splice(0)
      this.race24Columns = this.getDataColumns(data.teams)
      // for (const key in data.teams) {
      //   // console.log(data.teams[key])
      //   viewData.push(data.teams[key])
      // }
      viewData.push(...data.teams)
    },
    parseStatistics (data, viewData) {
       viewData.splice(0)
       this.teamStatColumns = this.getDataColumns(data.teams)
       viewData.push(...data.teams)
    },
    parseStatisticsPlayer (data, viewData) {
      // this.playerStatData.splice(0)
      viewData.splice(0)
      this.playerStatColumns = this.getDataColumns(data.players)
      viewData.push(...data.players)
    }
  }
}
</script>

<style scoped>
.form-group >>> legend {
  float: left;
  width: 150px;
}
.form-group >>> div {
  float: left;
}

.save-btn {
  position: fixed;
  right: 35px;
  top: 60px;
}

div.tabs >>> div ul li a {
  color: #000;
}
</style>
