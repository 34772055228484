import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const plugins = [createPersistedState()]

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    series: [],
    selectedSeries: '',
    tournaments: [],
    selectedTournamentId: '',
    matches: [],
    selectedMatch: '',
    teamRoster: [],
    userSession: '',
    isSigned: true,
    userName: '',
    role: null,
    showSeries: false,
    refreshTeamRoster: false,
    showTournaments: false,
    showMatches: false,
    toastErrorMsg: '',
    toastInfoMsg: '',
    selectedSeriesType: ''
  },
  mutations: {
    setUserName (state, val) {
      state.userName = val
    },
    role (state, val) {
      state.role = val
    },
    setIsSigned (state, val) {
      state.isSigned = val
    },
    setUserSession (state, val) {
      state.userSession = val
    },
    selectedSeries (state, val) {
      state.selectedSeries = val
    },
    selectedTournamentId (state, val) {
      state.selectedTournamentId = val
    },
    series (state, val) {
      state.series = val
    },
    tournaments (state, val) {
      state.tournaments = val
    },
    matches (state, val) {
      state.matches = val
    },
    selectedMatch (state, val) {
      state.selectedMatch = val
    },
    showSeries (state, val) {
      state.showSeries = val
    },
    refreshTeamRoster (state, val) {
      state.refreshTeamRoster = val
    },
    showTournaments (state, val) {
      state.showTournaments = val
    },
    showMatches (state, val) {
      state.showMatches = val
    },
    teamRoster (state, val) {
      state.teamRoster = val
    },
    toastErrorMsg (state, val) {
      state.toastErrorMsg = val
    },
    toastInfoMsg (state, val) {
      state.toastInfoMsg = val
    },
    selectedSeriesType (state, val) {
      state.selectedSeriesType = val
    }
  },
  getters: {},
  actions: {
    getTeamRoster ({ commit }) {
      const api = '/api/pubg/teamRoster/'
      axios
        .get(api)
        .then(response => {
          commit('teamRoster', response.data.data)
        })
        .catch(ex => {
          console.warn(`Request Fail: ${api}`, ex)
        })
    },
    login ({ commit, dispatch, state}, { id, pw }) {
      commit('selectedSeries', '')
      commit('selectedTournamentId', '')
      commit('selectedMatch', '')
      commit('series', [])
      commit('tournaments', [])
      commit('matches', [])
      commit('teamRoster', [])
      commit('role', null)
      commit('selectedSeriesType', '')
      const url = '/api/pubg/login'
      const param = {
        user_id: id,
        user_pw: pw
      }
      axios
        // .post('/api/pubg/login', loginData, { withCredentials: true })
        .post(url, param)
        .then(response => {
          if (!response.data) return
          if (!response.data.data) return
          if (!response.data.data.user_id) return

          const data = response.data.data

          commit('setUserSession', moment().unix())
          commit('setIsSigned', true)
          commit('setUserName', data.user_id)
          commit('role', data.role)
          dispatch('getTeamRoster')

          // 시리즈가 없는 경우
          if (!Array.isArray(data.series) || !data.series.length) {
            commit('series', [])
            dispatch('setSelectedSeries', '')

            return
          }

          commit('series', data.series)
          var tmpSeries = state.series.find(
            x => x.name === state.selectedSeries
          )
          if (!tmpSeries) {
            dispatch('setSelectedSeries', data.series[0].name)
            dispatch('selectedSeriesType', data.series[0].type)
          }

          // // 토너먼트가 없는 경우
          // if (!Array.isArray(curSeries.tournaments) || !curSeries.tournaments.length) return

          // var curTournament = curSeries.tournaments.find(x => x === state.selectedTournamentId)
          // if (!curTournament) {
          //   dispatch('setSelectedTournamentId', curSeries.tournaments[0])
          // }
        })
        .catch(ex => {
          console.warn(`Request fail: ${url}`, ex)

          if (ex.response) {
            commit('toastErrorMsg', ex.response.data.message)
          }
        })
    },
    logout ({ commit}) {
      commit('setIsSigned', false)
      commit('setUserSession', 0)
      commit('setUserName', '')
      commit('selectedSeries', '')
      commit('selectedTournamentId', '')
      commit('selectedMatch', '')
      commit('series', [])
      commit('tournaments', [])
      commit('matches', [])
      commit('role', null)
      commit('selectedSeriesType', '')
      axios
        .post('/api/pubg/logout')
        .then(() => {
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
      // this.$router.push('/')
    },
    selectedSeriesType ({ commit, state }, type) {
      if (type === state.selectedSeriesType) return
      commit('selectedSeriesType', type)
    },
    setSelectedSeries ({ commit, dispatch, state }, id) {
      if (id === state.selectedSeries) return

      commit('selectedSeries', id)

      if (!id) {
        commit('matchs', [])
        commit('selectedMatch', '')

        return
      }

      const curSeries = state.series.find(x => x.name === id)
      if (
        !curSeries ||
        !Array.isArray(curSeries.tournaments) ||
        !curSeries.tournaments.length
      ) {
        commit('matchs', [])
        commit('selectedMatch', '')

        return
      }

      commit('tournaments', curSeries.tournaments)

      var curTournament = curSeries.tournaments.find(
        x => x.name === state.selectedTournamentId
      )
      if (!curTournament) {
        dispatch('setSelectedTournamentId', curSeries.tournaments[0])
      }
    },
    setSelectedTournamentId ({ commit, dispatch, state }, id) {
      if (id === state.selectedTournamentId) return

      commit('selectedTournamentId', id)
      dispatch('getMatchList', id)
    },
    getMatchList ({commit, state}, id) {
      const api = `api/pubg/matchlist/${state.userName}/${id}`
      // console.log(api)
      axios
        .get(api)
        .then(response => {
          commit('matches', response.data.data)
          if (
            !response.data.data ||
            !Array.isArray(response.data.data) ||
            !response.data.data.length
          ) {
            commit('selectedMatch', '')
          } else {
            commit('selectedMatch', response.data.data[0])
          }
        })
        .catch(ex => {
          console.warn('get matchlist error', ex)
        })
    }
  },
  plugins
})
