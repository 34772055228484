<template>
  <div>
    <b-form-group label="Scoreboard Display Mode">
            <b-form-radio-group
              v-model="selectedRadio"
              @change="updateData"
              :options="options2"
              name="radio-inline2"
            ></b-form-radio-group>
          </b-form-group>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
    
  },
  mounted () {
    this.$store.commit('showSeries', true)
    this.selectedRadio = this.selectedScoreboardId
  },
  data () {
    return {
      waitMatch: false,
      waitIgnoreMatch: false,
      waitUpdate: false,
      selectedRadio:'',
    }
  },
  computed: {
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    selectedScoreboardId(){
      for(let i=0;i<this.series.length;i++){
        if(this.series[i].name === this.selectedSeries) {
          return this.series[i].scoreboard_sid
        }
      }
      return null
    },
    userName () {
      return this.$store.state.userName
    },
    series(){
      return this.$store.state.series 
    },
    seriesScoreboardType(){
      for(let i=0;i<this.series.length;i++){
        if(this.series[i].name === this.selectedSeries) {
          return this.series[i].scoreboard_types
        }
      }
      return null
    },
    options2(){
      if(this.seriesScoreboardType == undefined || this.seriesScoreboardType == null) return;
      const options = this.seriesScoreboardType.map(item => ({
        text: item.name,  // name 값을 text에 매핑
        value: item.sid   // sid 값을 value에 매핑
      }));
      return options;
    }
  },
  watch: {
    selectedSeries () {
      
    }
  },
  methods: {
    // scoreboard mode update api수행
    updateData () {
      if (!confirm('Update Data?'))
      {
        this.selectedRadio = this.selectedScoreboardId
        return
      }
      // selectedRadio => normal, total
      this.waitUpdate = true
      const url = '/api/pubg/updateScoreboardMode'
      const param = {
         account_id: this.userName,
         tournament_id: this.selectedTournamentId,
         series: this.selectedSeries,
         mode: this.selectedRadio
       }
      axios
      .post(url, param, {
        headers: {
          'x-token': 'mbccni##pubg',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.status === 200) {
          console.log('✅ updated');
          // this.$store.commit('scoreboardMode', this.selectedRadio);
        }
      }).catch(ex => {
        console.error('🚨 ERROR!!!!!:', ex); // 콘솔에 에러를 강조해서 출력
      }).then(() => {
        this.waitUpdate = false;
      });
    }
  }
}
</script>
<style scoped>

</style>
